<template>
    <div class="input_box">
        <div class="left_image">
            <img :class="id != 1 ? 'little_image' : ''" src="@/assets/img/image/defaultAva.svg" alt="">
        </div>
        <div class="right_comment">
            <div :id="`editor-container${id}`"
                style="width: 100%;  min-height: 32px !important; border: 1px solid transparent; padding: 0px;border-radius: 4px;background-color: #2e32380d;">
            </div>
            <div class="button_box">
                <el-popover ref="popover" placement="bottom" :offset="200" trigger="click">
                    <div class="emoji_box">
                        <div v-for="(item, index) in emojsList" class="image_box"
                            @click="insertEmoji(item.url, item.name)">
                            <img :src="item.url" :alt="item.name">

                        </div>
                    </div>
                    <i class="iconfont" slot="reference" style="color: #1c1f2373;cursor: pointer;">&#xe6ca;</i>
                </el-popover>
                <div>
                    <span class="cancel_btn" @click="cancleSend" v-if="id != 1"
                        style="color: #333; margin-right: 10px;">取消</span>
                    <span class="send_btn " :class="isSend ? 'isSend_btn' : ''" @click="sendContent">发送</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import  Quill from 'quill';
// import 'quill/dist/quill.snow.css'; // 引入 Quill 的 CSS 文件
import Quill from 'quill';
import "quill/dist/quill.core.css"; // 导入核心样式文件
import "quill/dist/quill.snow.css"; // 导入snow 主题样式
import "quill/dist/quill.bubble.css"; // 导入bubble 主题样式
import emojsList from "../../utils/emojis"
// import ImageBlot from "../../utils/quill-image-blot"
export default {
    props: ['id', "placeholderValue"],
    data() {
        return {
            emojsList: emojsList,
            quill: null,
            content: "有爱评论,说点好听的",
            isShow: false,
            isSend: false
        };
    },

    mounted() {
        this.initQuill();

    },
    methods: {
        getShow() {
            this.isShow = !this.isShow
        },
        initQuill() {
            // console.log(this.id, this.quill?.container.id, "this.quill");

            if (this.quill?.container.id != `#editor-container${this.id}` || !this.quill) {
                this.quill = new Quill(`#editor-container${this.id}`, {
                    theme: 'snow',
                    modules: {
                        toolbar: false // 移除工具栏
                    },
                    placeholder: this.placeholderValue
                });
            }
            this.addEditorEventListener();

            // console.log("调研", this.id);

        },
        insertEmoji(emojiSrc, name) {
            // const range = this.quill.getSelection(true);
            // this.quill.insertEmbed(range.index, 'image', { src: emojiSrc, alt: name, width: '20px', height: "20px" });
            // this.quill.setSelection(range.index + 1); 
            // this.$refs.popover.doClose();
            const range = this.quill.selection;
            this.quill.insertEmbed(range.savedRange.index, 'image', emojiSrc);
            const imgs = this.quill.root.querySelectorAll(`img[src="${emojiSrc}"]`);
            if (imgs) {
                imgs.forEach((item => {
                    if (!item.getAttribute("alt")) {
                        item.setAttribute('alt', name || '');
                    }
                }))
            }
            this.quill.setSelection(range.savedRange.index + 1);
            this.$refs.popover.doClose();

        },
        sendContent(event) {
            // 获取编辑器内容
            // this.content = this.quill.root.innerHTML;
            if (!this.isSend) return
            let str = this.quill.root.innerHTML
            // 替换image标签
            let noImgStr = this.replaceImgTagsWithAlt(str)
            // console.log(noImgStr, this.id, " this.content");
            if (noImgStr.length > 100) {
                this.$message.warning("评论字数超出限制")
                return
            }
            this.$emit('getSendMessage', noImgStr, this.id)
            this.quill.setContents([]);


        },
        replaceImgTagsWithAlt(html) {
            // 使用正则表达式匹配 <img> 标签并提取 alt 属性内容
            // return htmlString.replace(/<img\s+[^>]*alt="([^"]*)"[^>]*>/gi, (match, p1) => p1);
            // 去除 <span> 标签
            let cleanedHtml = html.replace(/<\/?span[^>]*>/g, '');

            // 处理 <p> 标签，将 <p> 替换为换行符，并去除 </p>
            cleanedHtml = cleanedHtml.replace(/<p[^>]*>/g, '\n').replace(/<\/p>/g, '');

            // 处理 <img> 标签（如果有）
            cleanedHtml = cleanedHtml.replace(/<img[^>]*alt="([^"]*)"[^>]*>/g, '$1');

            // 去除所有其他 HTML 标签
            cleanedHtml = cleanedHtml.replace(/<\/?[^>]+>/g, '');

            // 去除多余的换行符
            return cleanedHtml.trim().replace(/\n+/g, '\n');
        },
        addEditorEventListener() {
            // 监听 Quill 编辑器内容变化事件

            this.adjustEditorHeight();

        },
        adjustEditorHeight() {
            this.textChangeListener = (delta, oldDelta, source) => {
                // const content = this.quill.getText().trim();
                this.isSend = true;
                if (this.quill.root.innerHTML == "<p><br></p>") {
                    this.isSend = false;
                }
            };
            // 绑定 `text-change` 事件监听器
            this.quill.on('text-change', this.textChangeListener);
        },
        cancleSend() {
            this.quill.setContents([]);
            this.$emit("hideInput", this.id)
        }
    },
    destroyed() {

    }
};
</script>
<style scoped lang="scss">
.input_box {
    width: 100%;
    display: flex;

    .left_image {
        img {
            width: 40px;
            height: 40px;

        }

        .little_image {
            width: 32px;
            height: 32px !important;
        }

        padding-right: 10px;
    }

    .right_comment {
        flex: 1;
        width: 0;
        flex-shrink: 0;
        position: relative;

        .placeholder {
            width: 500px;
            position: absolute;
            height: 36px;
            top: 7px;
            left: 13px;
            color: #25263259;

            &:hover {
                display: none;
            }
        }

        .button_box {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            color: #25263259;

            i {
                font-size: 20px;
                color: #25263259;
            }

            .send_btn {
                height: 24px;
                padding: 6px 12px;
                font-size: 12px;
                background-color: #f2f2f4;
                cursor: not-allowed;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;

            }

            .isSend_btn {
                background-color: #fe2c55;
                color: #fff;
                cursor: pointer;
            }

            .cancel_btn {
                height: 24px;
                padding: 6px 12px;
                font-size: 12px;
                background-color: #f2f2f4;
                cursor: pointer;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;

                &:hover {
                    background-color: #e4e4e6;
                }
            }

            .send_btn_disable {
                cursor: not-allowed;
            }
        }
    }
}

// ::v-deep .popper__arrow{
//     display: none !important;
// }
.emoji_box {
    display: flex;
    flex-wrap: wrap;
    max-width: 472px;
    max-height: 254px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 9px 6px;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }

    .image_box {
        width: 32px;
        height: 32px;
        margin: 10px;

        img {
            width: 100%;
            height: 100%;
        }

    }

}
</style>
<style>
.ql-container {
    height: auto;
}

.ql-editor {
    min-height: 32px !important;
    padding: 0px 15px !important;
    line-height: 32px !important;
    height: auto;
    border: 1px solid transparent;

    &:hover {
        background-color: #f2f2f4 !important;
        border: 1px solid #fe2c55;
        border-radius: 4px !important;
    }

    p {
        line-height: 32px !important;
        font-size: 16px;
        /* display: inline !important; */


        img {

            /* 确保图片在容器内缩放 */
            max-width: 21px !important;
            height: auto !important;
            /* 图片最大宽度为100% */
            /* 高度自动调整 */
            cursor: nwse-resize;
            line-height: 32px !important;
            margin-right: 2px;
            vertical-align: middle;
        }
    }


}
</style>