import { render, staticRenderFns } from "./CommentManagement.vue?vue&type=template&id=2c7bc8c2&scoped=true"
import script from "./CommentManagement.vue?vue&type=script&lang=js"
export * from "./CommentManagement.vue?vue&type=script&lang=js"
import style0 from "./CommentManagement.vue?vue&type=style&index=0&id=2c7bc8c2&prod&lang=scss&scoped=true"
import style1 from "./CommentManagement.vue?vue&type=style&index=1&id=2c7bc8c2&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c7bc8c2",
  null
  
)

export default component.exports