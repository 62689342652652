

const emojList = [
    {
        name: "[微笑]",
        url: require("../assets/img/emojs/s01.png"),
        value: 's01'
    },
    {
        name: "[色]",
        url: require("../assets/img/emojs/s02.png"),
        value: 's02'
    },
    {
        name: "[爱慕]",
        url: require("../assets/img/emojs/s03.png"),
        value: 's03'
    },
    {
        name: "[捂脸]",
        url: require("../assets/img/emojs/s05.png"),
        value: 's04'
    },
    {
        name: "[呲牙]",
        url: require("../assets/img/emojs/s05.png"),
        value: 's05'
    },
    {
        name: "[大笑]",
        url: require("../assets/img/emojs/s06.png"),
        value: 's06'
    },
    {
        name: "[发怒]",
        url: require("../assets/img/emojs/s07.png"),
        value: 's07'
    },
    {
        name: "[灵机一动]",
        url: require("../assets/img/emojs/s08.png"),
        value: 's08'
    },
    {
        name: "[灵光一闪]",
        url: require("../assets/img/emojs/s09.png"),
        value: 's09'
    },
    {
        name: "[抠鼻]",
        url: require("../assets/img/emojs/s10.png"),
        value: 's10'
    },
    {
        name: "[害羞]",
        url: require("../assets/img/emojs/s11.png"),
        value: 's11'
    },
    {
        name: "[调皮]",
        url: require("../assets/img/emojs/s12.png"),
        value: 's12'
    },
    {
        name: "[可爱]",
        url: require("../assets/img/emojs/s13.png"),
        value: 's13'
    },
    {
        name: "[吃瓜群众]",
        url: require("../assets/img/emojs/s14.png"),
        value: 's14'
    },
    {
        name: "[晕]",
        url: require("../assets/img/emojs/s15.png"),
        value: 's15'
    },
    {
        name: "[闭嘴]",
        url: require("../assets/img/emojs/s16.png"),
        value: 's16'
    },
    {
        name: "[笑哭]",
        url: require("../assets/img/emojs/s17.png"),
        value: 's17'
    },
    {
        name: "[难过]",
        url: require("../assets/img/emojs/s18.png"),
        value: 's18'
    },
    {
        name: "[亲亲]",
        url: require("../assets/img/emojs/s19.png"),
        value: 's19'
    },
    {
        name: "[来看我]",
        url: require("../assets/img/emojs/s20.png"),
        value: 's20'
    },
    {
        name: "[偷笑]",
        url: require("../assets/img/emojs/s21.png"),
        value: 's21'
    },
    {
        name: "[打脸]",
        url: require("../assets/img/emojs/s22.png"),
        value: 's22'
    },
    {
        name: "[翻白眼]",
        url: require("../assets/img/emojs/s23.png"),
        value: 's23'
    },
    {
        name: "[睡]",
        url: require("../assets/img/emojs/s24.png"),
        value: 's24'
    },
    {
        name: "[鼾睡]",
        url: require("../assets/img/emojs/s25.png"),
        value: 's25'
    },
    {
        name: "[奸笑]",
        url: require("../assets/img/emojs/s26.png"),
        value: 's26'
    },
    {
        name: "[送心]",
        url: require("../assets/img/emojs/s27.png"),
        value: 's27'
    },
    {
        name: "[大哭]",
        url: require("@/assets/img/emojs/s28.png"),
        value: 's28'
    },
    {
        name: "[抓狂]",
        url: require("@/assets/img/emojs/s29.png"),
        value: 's29'
    },
    {
        name: "[惊讶]",
        url: require("@/assets/img/emojs/s30.png"),
        value: 's30'
    },
    {
        name: "[酷拽]",
        url: require("../assets/img/emojs/s31.png"),
        value: 's31'
    },
    {
        name: "[泣不成声]",
        url: require("../assets/img/emojs/s32.png"),
        value: 's32'
    },
    {
        name: "[大金牙]",
        url: require("../assets/img/emojs/s33.png"),
        value: 's33'
    },
    {
        name: "[疑问]",
        url: require("../assets/img/emojs/s34.png"),
        value: 's34'
    },
    {
        name: "[小鼓掌]",
        url: require("../assets/img/emojs/s35.png"),
        value: 's35'
    },
    {
        name: "[吐]",
        url: require("../assets/img/emojs/s36.png"),
        value: 's36'
    },
    {
        name: "[拥抱]",
        url: require("../assets/img/emojs/s37.png"),
        value: 's37'
    },
    {
        name: "[惊恐]",
        url: require("../assets/img/emojs/s38.png"),
        value: 's38'
    },
    {
        name: "[耶]",
        url: require("../assets/img/emojs/s39.png"),
        value: 's39'
    },
    {
        name: "[醉了]",
        url: require("../assets/img/emojs/s40.png"),
        value: 's40'
    },
    {
        name: "[看]",
        url: require("../assets/img/emojs/s41.png"),
        value: 's41'
    },
    {
        name: "[二哈]",
        url: require("../assets/img/emojs/s42.png"),
        value: 's42'
    },
    {
        name: "[微笑袋鼠]",
        url: require("../assets/img/emojs/s43.png"),
        value: 's43'
    },
    {
        name: "[冷漠]",
        url: require("../assets/img/emojs/s44.png"),
        value: 's44'
    },
    {
        name: "[暗中观察]",
        url: require("../assets/img/emojs/s45.png"),
        value: 's45'
    },
    {
        name: "[凝视]",
        url: require("../assets/img/emojs/s46.png"),
        value: 's46'
    },
    {
        name: "[握爪]",
        url: require("../assets/img/emojs/s47.png"),
        value: 's47'
    },
    {
        name: "[锦鲤]",
        url: require("../assets/img/emojs/s48.png"),
        value: 's48'
    },
    {
        name: "[蜡烛]",
        url: require("../assets/img/emojs/s49.png"),
        value: 's49'
    },
    {
        name: "[加一]",
        url: require("../assets/img/emojs/s50.png"),
        value: 's50'
    },
    {
        name: "[我酸了]",
        url: require("../assets/img/emojs/s51.png"),
        value: 's51'
    },
    {
        name: "[加鸡腿]",
        url: require("../assets/img/emojs/s52.png"),
        value: 's52'
    },
    {
        name: "[我太南了]",
        url: require("../assets/img/emojs/s53.png"),
        value: 's53'
    },
    {
        name: "[扎心]",
        url: require("../assets/img/emojs/s54.png"),
        value: 's54'
    },
    {
        name: "[给跪了]",
        url: require("../assets/img/emojs/s55.png"),
        value: 's55'
    },
    {
        name: "[赞]",
        url: require("../assets/img/emojs/s56.png"),
        value: 's56'
    },
    {
        name: "[鼓掌]",
        url: require("../assets/img/emojs/s57.png"),
        value: 's57'
    },
    {
        name: "[比心]",
        url: require("../assets/img/emojs/s58.png"),
        value: 's58'
    },
    {
        name: "[祈祷]",
        url: require("../assets/img/emojs/s59.png"),
        value: 's59'
    },
    {
        name: "[感谢]",
        url: require("../assets/img/emojs/s60.png"),
        value: 's60'
    },
    {
        name: "[胜利]",
        url: require("../assets/img/emojs/s61.png"),
        value: 's61'
    },
    {
        name: "[强壮]",
        url: require("../assets/img/emojs/s62.png"),
        value: 's62'
    },
    {
        name: "[加油]",
        url: require("../assets/img/emojs/s63.png"),
        value: 's63'
    },
    {
        name: "[OK]",
        url: require("../assets/img/emojs/s64.png"),
        value: 's64'
    },
    {
        name: "[ok]",
        url: require("../assets/img/emojs/s65.png"),
        value: 's25'
    },
    {
        name: "[弱]",
        url: require("../assets/img/emojs/s66.png"),
        value: 's66'
    },
    {
        name: "[抱拳]",
        url: require("../assets/img/emojs/s67.png"),
        value: 's67'
    },
    {
        name: "[勾引]",
        url: require("../assets/img/emojs/s68.png"),
        value: 's68'
    },
    {
        name: "[再见]",
        url: require("../assets/img/emojs/s69.png"),
        value: 's69'
    },
    {
        name: "[握手]",
        url: require("../assets/img/emojs/s70.png"),
        value: 's70'
    },
    {
        name: "[玫瑰]",
        url: require("../assets/img/emojs/s71.png"),
        value: 's71'
    },
    {
        name: "[666]",
        url: require("../assets/img/emojs/s72.png"),
        value: 's72'
    },
    {
        name: "[爱心]",
        url: require("../assets/img/emojs/s73.png"),
        value: 's73'
    },
    {
        name: "[心]",
        url: require("../assets/img/emojs/s74.png"),
        value: 's74'
    },
    {
        name: "[胡瓜]",
        url: require("../assets/img/emojs/s75.png"),
        value: 's75'
    },
    {
        name: "[嘴唇]",
        url: require("../assets/img/emojs/s76.png"),
        value: 's76'
    },
    {
        name: "[kiss]",
        url: require("../assets/img/emojs/s77.png"),
        value: 's77'
    },
    {
        name: "[给力]",
        url: require("../assets/img/emojs/s78.png"),
        value: 's78'
    },
    {
        name: "[啤酒]",
        url: require("../assets/img/emojs/s79.png"),
        value: 's79'
    },
    {
        name: "[派对]",
        url: require("../assets/img/emojs/s80.png"),
        value: 's80'
    },
    {
        name: "[撒花]",
        url: require("../assets/img/emojs/s81.png"),
        value: 's81'
    },
    {
        name: "[蛋糕]",
        url: require("../assets/img/emojs/s82.png"),
        value: 's82'
    },
    {
        name: "[红包]",
        url: require("../assets/img/emojs/s83.png"),
        value: 's83'
    },
    {
        name: "[礼物]",
        url: require("../assets/img/emojs/s84.png"),
        value: 's84'
    },
    {
        name: "[发]",
        url: require("../assets/img/emojs/s85.png"),
        value: 's85'
    },
    {
        name: "[咖啡]",
        url: require("../assets/img/emojs/s86.png"),
        value: 's86'
    },
    {
        name: "[太阳]",
        url: require("../assets/img/emojs/s87.png"),
        value: 's87'
    },
    {
        name: "[月亮]",
        url: require("../assets/img/emojs/s88.png"),
        value: 's88'
    },
    {
        name: "[伤心]",
        url: require("../assets/img/emojs/s89.png"),
        value: 's89'
    },
    {
        name: "[心碎]",
        url: require("../assets/img/emojs/s90.png"),
        value: 's90'
    },
    {
        name: "[便便]",
        url: require("../assets/img/emojs/s91.png"),
        value: 's91'
    },
    {
        name: "[福]",
        url: require("../assets/img/emojs/s92.png"),
        value: 's92'
    },
    {
        name: "[一起加油]",
        url: require("../assets/img/emojs/s93.png"),
        value: 's93'
    },
    {
        name: "[戴口罩]",
        url: require("../assets/img/emojs/s94.png"),
        value: 's94'
    },
    {
        name: "[勤洗手]",
        url: require("../assets/img/emojs/s95.png"),
        value: 's95'
    },
    {
        name: "[不信谣言]",
        url: require("../assets/img/emojs/s96.png"),
        value: 's96'
    },
    {
        name: "[情书]",
        url: require("../assets/img/emojs/s97.png"),
        value: 's97'
    },
    {
        name: "[iloveyou]",
        url: require("../assets/img/emojs/s98.png"),
        value: 's98'
    },
    {
        name: "[巧克力]",
        url: require("../assets/img/emojs/s99.png"),
        value: 's99'
    },
    {
        name: "[戒指]",
        url: require("../assets/img/emojs/s100.png"),
        value: 's100'
    },
    {
        name: "[流泪]",
        url: require("../assets/img/emojs/s101.png"),
        value: 's101'
    },
    {
        name: "[愉快]",
        url: require("../assets/img/emojs/s102.png"),
        value: 's102'
    },
    {
        name: "[笑]",
        url: require("../assets/img/emojs/s1021.png"),
        value: 's1021'
    },
    {
        name: "[发呆]",
        url: require("../assets/img/emojs/s103.png"),
        value: 's103'
    },
    {
        name: "[惊呆]",
        url: require("../assets/img/emojs/s1031.png"),
        value: 's1031'
    },
    {
        name: "[机智]",
        url: require("../assets/img/emojs/s104.png"),
        value: 's104'
    },
    {
        name: "[快哭了]",
        url: require("../assets/img/emojs/s105.png"),
        value: 's105'
    },
    {
        name: "[击掌]",
        url: require("../assets/img/emojs/s106.png"),
        value: 's106'
    },
    {
        name: "[黑脸]",
        url: require("../assets/img/emojs/s107.png"),
        value: 's107'
    },
    {
        name: "[飞吻]",
        url: require("../assets/img/emojs/s108.png"),
        value: 's108'
    },
    {
        name: "[碰拳]",
        url: require("../assets/img/emojs/s109.png"),
        value: 's109'
    },
    {
        name: "[舔屏]",
        url: require("../assets/img/emojs/s110.png"),
        value: 's110'
    },
    {
        name: "[憨笑]",
        url: require("../assets/img/emojs/s111.png"),
        value: 's111'
    },
    {
        name: "[我想静静]",
        url: require("../assets/img/emojs/s112.png"),
        value: 's112'
    },
    {
        name: "[思考]",
        url: require("../assets/img/emojs/s113.png"),
        value: 's113'
    },
    {
        name: "[呆无辜]",
        url: require("../assets/img/emojs/s114.png"),
        value: 's114'
    },
    {
        name: "[黑线]",
        url: require("../assets/img/emojs/s115.png"),
        value: 's115'
    },
    {
        name: "[尴尬]",
        url: require("../assets/img/emojs/s1151.png"),
        value: 's1151'
    },
    {
        name: "[得意]",
        url: require("../assets/img/emojs/s116.png"),
        value: 's116'
    },
    {
        name: "[衰]",
        url: require("../assets/img/emojs/s117.png"),
        value: 's117'
    },
    {
        name: "[互粉]",
        url: require("../assets/img/emojs/s118.png"),
        value: 's118'
    },
    {
        name: "[吐血]",
        url: require("../assets/img/emojs/s119.png"),
        value: 's119'
    },
    {
        name: "[可怜]",
        url: require("../assets/img/emojs/s120.png"),
        value: 's120'
    },
    {
        name: "[不看]",
        url: require("../assets/img/emojs/s121.png"),
        value: 's121'
    },
    {
        name: "[摸头]",
        url: require("../assets/img/emojs/s122.png"),
        value: 's122'
    },
    {
        name: "[去污粉]",
        url: require("../assets/img/emojs/s123.png"),
        value: 's123'
    },
    {
        name: "[钱]",
        url: require("../assets/img/emojs/s124.png"),
        value: 's124'
    },
    {
        name: "[撇嘴]",
        url: require("../assets/img/emojs/s125.png"),
        value: 's125'
    },
    {
        name: "[震惊]",
        url: require("../assets/img/emojs/s126.png"),
        value: 's126'
    },
    {
        name: "[V5]",
        url: require("../assets/img/emojs/s127.png"),
        value: 's127'
    },
    {
        name: "[菜刀]",
        url: require("../assets/img/emojs/s128.png"),
        value: 's128'
    },
    {
        name: "[刀]",
        url: require("../assets/img/emojs/s1281.png"),
        value: 's1281'
    },
    {
        name: "[做鬼脸]",
        url: require("../assets/img/emojs/s129.png"),
        value: 's129'
    },
    {
        name: "[皱眉]",
        url: require("../assets/img/emojs/s130.png"),
        value: 's130'
    },
    {
        name: "[敲打]",
        url: require("../assets/img/emojs/s131.png"),
        value: 's131'
    },
    {
        name: "[尬笑]",
        url: require("../assets/img/emojs/s132.png"),
        value: 's132'
    },
    {
        name: "[恐惧]",
        url: require("../assets/img/emojs/s133.png"),
        value: 's133'
    },
    {
        name: "[惊喜]",
        url: require("../assets/img/emojs/s134.png"),
        value: 's134'
    },
    {
        name: "[石化]",
        url: require("../assets/img/emojs/s135.png"),
        value: 's135'
    },
    {
        name: "[哈欠]",
        url: require("../assets/img/emojs/s136.png"),
        value: 's136'
    },
    {
        name: "[炸弹]",
        url: require("../assets/img/emojs/s137.png"),
        value: 's137'
    },
    {
        name: "[嘘]",
        url: require("../assets/img/emojs/s138.png"),
        value: 's138'
    },
    {
        name: "[吐舌]",
        url: require("../assets/img/emojs/s139.png"),
        value: 's139'
    },
    {
        name: "[委屈]",
        url: require("../assets/img/emojs/s140.png"),
        value: 's140'
    },
    {
        name: "[吐彩虹]",
        url: require("../assets/img/emojs/s141.png"),
        value: 's141'
    },
    {
        name: "[奋斗]",
        url: require("../assets/img/emojs/s142.png"),
        value: 's142'
    },
    {
        name: "[雾霾]",
        url: require("../assets/img/emojs/s143.png"), value: 's143'
    },
    {
        name: "[生病]",
        url: require("../assets/img/emojs/s1431.png"), value: 's1431'
    },
    {
        name: "[擦汗]",
        url: require("../assets/img/emojs/s144.png"), value: 's144'
    },
    {
        name: "[如花]",
        url: require("../assets/img/emojs/s145.png"),
        value: '145'
    },
    {
        name: "[鄙视]",
        url: require("../assets/img/emojs/s146.png"),
        value: 's146'
    },
    {
        name: "[强]",
        url: require("../assets/img/emojs/s147.png"),
        value: 's147'
    },
    {
        name: "[紫薇别走]",
        url: require("../assets/img/emojs/s148.png"),
        value: 's148'
    },
    {
        name: "[红脸]",
        url: require("../assets/img/emojs/s149.png"),
        value: 's149'
    },
    {
        name: "[困]",
        url: require("../assets/img/emojs/s150.png"),
        value: 's150'
    },
    {
        name: "[流汗]",
        url: require("../assets/img/emojs/s151.png"),
        value: 's151'
    },
    {
        name: "[汗]",
        url: require("../assets/img/emojs/s1511.png"),
        value: 's1511'
    },
    {
        name: "[绿帽子]",
        url: require("../assets/img/emojs/s152.png"),
        value: 's152'
    },
    {
        name: "[左上]",
        url: require("../assets/img/emojs/s153.png"),
        value: 's153'
    },
    {
        name: "[熊吉]",
        url: require("../assets/img/emojs/s154.png"),
        value: 's154'
    },
    {
        name: "[听歌]",
        url: require("../assets/img/emojs/s155.png"),
        value: 's155'
    },
    {
        name: "[骷髅]",
        url: require("../assets/img/emojs/s156.png"),
        value: 's156'
    },
    {
        name: "[18禁]",
        url: require("../assets/img/emojs/s157.png"),
        value: 's157'
    },
    {
        name: "[西瓜]",
        url: require("../assets/img/emojs/s158.png"),
        value: 's158'
    },
    {
        name: "[斜眼]",
        url: require("../assets/img/emojs/s159.png"),
        value: 's159'
    },
    {
        name: "[阴险]",
        url: require("../assets/img/emojs/s160.png"),
        value: 's160'
    },
    {
        name: "[白眼]",
        url: require("../assets/img/emojs/s161.png"),
        value: 's161'
    },
    {
        name: "[凋谢]",
        url: require("../assets/img/emojs/s162.png"),
        value: 's162'
    },
    {
        name: "[嘿哈]",
        url: require("../assets/img/emojs/s163.png"),
        value: 's163'
    },
    {
        name: "[坏笑]",
        url: require("../assets/img/emojs/s164.png"),
        value: 's164'
    },
    {
        name: "[加好友]",
        url: require("../assets/img/emojs/s165.png"),
        value: 's165'
    },
    {
        name: "[囧]",
        url: require("../assets/img/emojs/s166.png"),
        value: 's166'
    },
    {
        name: "[泪奔]",
        url: require("../assets/img/emojs/s167.png"),
        value: 's167'
    },
    {
        name: "[不失礼貌的微笑]",
        url: require("../assets/img/emojs/s168.png"),
        value: 's168'
    },
    {
        name: "[拳头]",
        url: require("../assets/img/emojs/s169.png"),
        value: 's169'
    },
    {
        name: "[右边]",
        url: require("../assets/img/emojs/s170.png"),
        value: 's170'
    },
    {
        name: "[右哼哼]",
        url: require("../assets/img/emojs/s171.png"),
        value: 's171'
    },
    {
        name: "[悠闲]",
        url: require("../assets/img/emojs/s172.png"),
        value: 's172'
    },
    {
        name: "[绝望的凝视]",
        url: require("../assets/img/emojs/s173.png"),
        value: 's173'
    },
    {
        name: "[咒骂]",
        url: require("../assets/img/emojs/s174.png"),
        value: 's174'
    },
    {
        name: "[猪头]",
        url: require("../assets/img/emojs/s175.png"),
        value: 's175'
    },
    {
        name: "[左边]",
        url: require("../assets/img/emojs/s176.png"),
        value: 's176'
    },
    {
        name: "[左哼哼]",
        url: require("../assets/img/emojs/s177.png"),
        value: 's177'
    },

]
export default emojList